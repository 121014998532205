<template>
  <div class="conpanyDetai popup-detail common-pop">
    <el-dialog
      :title="todo == 'add' ? '【新增】公司信息' : (todo == 'edit' ? '【修改】公司信息' : '【查看】公司信息')"
      :close-on-click-modal="false"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" size="small">
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="公司编号" prop="companyNo">
              <el-input v-model="dataForm.companyNo" placeholder="公司编号" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业信用代码" prop="creditCode">
              <el-input v-model="dataForm.creditCode" placeholder="企业信用代码" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="公司全称" prop="fullName">
              <el-input v-model="dataForm.fullName" placeholder="公司全称" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司简称" prop="simpleName">
              <el-input v-model="dataForm.simpleName" placeholder="公司简称" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="联系人名称" prop="contactName">
              <el-input v-model="dataForm.contactName" placeholder="联系人名称" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人电话" prop="contactPhone">
              <el-input v-model="dataForm.contactPhone" placeholder="可输入手机号码或固定电话" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="18">
            <el-form-item label="营业执照">
              <!-- 文件列表；uploadUrl：文件路径；limit： 限制文件个数 -->
              <al-upload :fileData="dataForm.enclosureInfoList" :isView="isView" :fileUrl="uploadUrl" :limit="2" :businessType="1000" @getFileData="getFileData"></al-upload>
              <!-- <el-upload
                class="upload-demo"
                :action="uploadUrl"
                :data="{businessType: 1000}"
                :headers="{'access_token': accessToken}"
                :limit="2"
                :file-list="licenseList"
                :on-success="saveFileList"
                :on-remove="removeFileList">
                <el-button size="mini" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传2张，且不超过500kb</div>
                 <div slot="file" slot-scope="{file}">
                   <span @click="changeUpload">{{file.name}}</span>
                  <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span class="el-upload-list__item-delete" @click="handleDownload(file)">
                        <i class="el-icon-download"></i>
                      </span>
                      <span class="el-upload-list__item-delete" @click="removeFileList(file)">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                </div>
              </el-upload> -->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="联系地址" prop="address">
              <el-input v-model="dataForm.address" placeholder="联系地址" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="dataForm.remark" placeholder="备注" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="!isView">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      uploadUrl: '',
      accessToken: '',
      licenseList: [],
      dataForm: {
        id: null,
        companyNo: null,
        creditCode: null,
        fullName: null,
        simpleName: null,
        contactName: null,
        contactPhone: null,
        address: null,
        remark: null,
        enclosureInfoList: []
      },
      todo: 'view',
      dataRule: {
        fullName: this._Valid.init(['null']),
        companyNo: this._Valid.init(['null']),
        simpleName: this._Valid.init(['null']),
        contactName: this._Valid.init(['null']),
        contactPhone: this._Valid.init(['null'])
      }
    }
  },
  computed: {
    isView () {
      return this.todo === 'view'
    }
  },
  methods: {
    // 获取附件信息
    getFileData (data) {
      this.dataForm.enclosureInfoList = data.fileList
    },
    // 修改时文件反显
    initFile (data) {
      let infoList = data.enclosureInfoList
      if (infoList) {
        this.dataForm.enclosureInfoList = data.enclosureInfoList
        // 不直接赋值的原因是引用的是一个数组，删除会有问题(表面删除了实际数据没有)
        for (let i = 0; i < infoList.length; i++) {
          this.licenseList.push({id: infoList[i].id, name: infoList[i].name, url: infoList[i].url})
        }
      }
    },
    // 弹窗打开
    init (id, todo) {
      this.uploadUrl = this.$http.adornUrl('/upload/img')
      this.accessToken = this.$cookie.get('TmsToken')
      this.licenseList = []
      this.dataForm.id = id
      this.todo = todo
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/company/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          if (data) {
            this.dataForm.companyNo = data.companyNo
            this.dataForm.creditCode = data.creditCode
            this.dataForm.fullName = data.fullName
            this.dataForm.simpleName = data.simpleName
            this.dataForm.address = data.address
            this.dataForm.contactName = data.contactName
            this.dataForm.contactPhone = data.contactPhone
            this.dataForm.remark = data.remark
            this.initFile(data)
          }
        })
      }
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          this.$http({
            url: this.$http.adornUrl(`/company/` + methodName),
            method: method,
            data: this.$http.adornData({
              'id': this.dataForm.id || undefined,
              'companyNo': this.dataForm.companyNo,
              'creditCode': this.dataForm.creditCode,
              'fullName': this.dataForm.fullName,
              'simpleName': this.dataForm.simpleName,
              'address': this.dataForm.address,
              'contactName': this.dataForm.contactName,
              'contactPhone': this.dataForm.contactPhone,
              'enclosureInfoList': this.dataForm.enclosureInfoList,
              'remark': this.dataForm.remark
            })
          }).then(({data}) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    },
    changeUpload (data) {
    }
  }
}
</script>
